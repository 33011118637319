<template>
  <div>
    <!-- Button trigger modal -->
    <button
      data-toggle="modal"
      id="btn-modal-pesquisa"
      data-target="#modal-pesquisa"
      class="btn btn-primary opacity-0 position-absolute invisible"
    />

    <!-- Modal -->
    <div
      class="modal fade"
      id="modal-pesquisa"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content pt-5 pb-4 px-4">
          <h5 class="upper pink mb-3 text-center">
            {{ titulo }}
          </h5>

          <p class="text-secondary mb-4">
            {{ descricao }}
          </p>

          <form @submit.prevent="enviar">
            <div
              v-for="item in questoes"
              :key="item.questao.id"
            >
              <h6 class="mb-3">
                {{ item.questao.titulo }}
              </h6>

              <FieldOptions
                required
                class="mb-3"
                :id="item.questao.id"
                v-model="item.resposta"
                :options="item.opcoes"
                :is-inline="item.questao.id !== 1"
              />
              <InputPesquisa
                required
                v-model="item.respostaSubjetiva"
                v-if="isSubjective(item.opcoes, item.resposta)"
              />
            </div>

            <div class="row justify-content-center">
              <div class="col-auto">
                <button
                  type="submit"
                  class="btn-pink btn text-white py-0 upper px-5 d-grid"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SharedService from '@/service/sharedServices'
import FieldOptions from '@/components/shared/forms/FieldOptions'
import InputPesquisa from '@/components/shared/forms/InputPesquisa'

export default {
  name: 'PesquisaComp',

  components: { InputPesquisa, FieldOptions },

  data () {
    return {
      descricao: '',
      id: '',
      titulo: '',
      questoes: [],
      from: '',
      course: ''
    }
  },

  created () {
    this.SharedService = new SharedService(this.$resource)
    this.getSearchs()
  },

  methods: {
    isSubjective (opcoes, resposta) {
      const opt = opcoes.find(({ id }) => id === resposta)
      return opt?.objetiva === 'N'
    },

    async getSearchs () {
      const { body } = await this.SharedService.getPesquisas()
      if (!body.length) return

      const [{ pesquisa, questoes }] = body
      this.questoes = questoes.map(q => ({ ...q, resposta: '', respostaSubjetiva: '' }))
      this.id = pesquisa.id
      this.titulo = pesquisa.titulo
      this.descricao = pesquisa.descricao
      this.toggleModal()
    },

    toggleModal () {
      setTimeout(() => {
        document.getElementById('btn-modal-pesquisa').click()
      }, 300)
    },

    async enviar () {
      const isIncomplete = this.questoes.find(q => !q.resposta)
      if (isIncomplete) return this.$sweet.sweetMensagem('Por favor, preencha todos os campos!')

      this.$sweet.sweetWait('Aguarde enquanto estamos salvando!')

      const params = {
        id: this.id,
        respostas: this.questoes.map(q => {
          return {
            idQuestao: q.questao.id,
            idOpcao: q.resposta,
            ...(q.respostaSubjetiva && { respostaSubjetiva: q.respostaSubjetiva })
          }
        })
      }

      const { body } = await this.SharedService.savePesquisa(params)
      this.$sweet.sweetSuccess('Salvo com sucesso')
      this.toggleModal()
    }
  }
}
</script>

<style lang="sass" scoped>
.modal-content
  background-color: #000
  background-size: cover
  background-image: url('@/assets/images/bg_formulario.png')
h5
  letter-spacing: 2px
  font-size: 26px !important
  font-weight: bold !important
p
  text-align: justify
label
  font-size: 12px
.marq
  text-style: italic !important
.btn-pink
  padding-top: 3px !important
  place-items: center
@media (min-width: 576px)
  .modal-dialog
    max-width: 550px !important
    margin: 1.75rem auto
</style>
