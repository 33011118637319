<template>
  <section
    :class="['pb-3 pt-4 pt-md-2', menuIsOpen ? 'px-1' : 'px-0 px-md-5']"
    v-if="isLoading || cursos.length"
  >
    <h3 class="ms-2 mb-2 mb-md-3 bold bold-xs-none title title-bordered">
      {{ assistindo.length ? "Continue assistindo" : "Mais assistidos" }}
    </h3>

    <LoadingComp v-if="isLoading" />

    <Slider
      v-else-if="cursos.length"
      :show-infos="true"
      :images="cursos"
      :slides-to-show="slidesToShow"
      father-class="doc-slider-more"
    />
  </section>
</template>

<script>
import Slider from "@/components/shared/Slider";
import CursoService from "@/service/cursoService";
import { mapGetters } from "vuex";

export default {
  components: {
    Slider,
  },

  created() {
    if (this.isLoading || this.cursos.length) return;
    this.load();
  },

  methods: {
    async load() {
      this.CursoService = new CursoService(this.$resource);
      this.$store.dispatch("cursos/setIsLoading", true);
      const url = this.$baseApiURL;
      const { body: cursos } = await this.CursoService.cursosAtivos();
      this.$store.dispatch("cursos/setIsLoading", false);
      this.$store.dispatch("cursos/setCursos", { cursos, url });
    },
  },

  computed: {
    slidesToShow() {
      if (this.width < 600) return 2;
      if (this.width < 800) return 3;
      return 4;
    },

    ...mapGetters({
      menuIsOpen: "configs/menuIsOpen",
      width: "configs/getWidth",
      isLoading: "cursos/isLoading",
      allCursos: "cursos/getCursos",
    }),

    assistindo() {
      return (
        this.mapedVideos.filter((video) => {
          return (
            video.tempoConcluido &&
            video.tempoConcluido < video.tempoTotalDuracao
          );
        }) || []
      );
    },

    mapedVideos() {
      return (
        this.allCursos.map((item) => {
          const extensao = item.extensaoBannerInterno || item.extensaoBanner;
          const expressao = item.extensaoBannerInterno
            ? "banner_interno"
            : "horizontal";
          item.image = `${this.$baseApiURL}/acesso-arquivo?caminho=curso&identificador=${item.id}&extensao=${extensao}&expressao=${expressao}`;
          return item;
        }) || []
      );
    },

    cursos() {
      return this.assistindo.length
        ? this.assistindo
        : this.mapedVideos
            .filter((video) => {
              return video.disponivelEmBreve !== "S";
            })
            .slice(0, 4);
    },
  },
};
</script>

<style lang="sass">
.doc-slider-more
  .arrow-right
    top: 35% !important
    right: -35px !important
  .arrow-left
    top: 35% !important
    left: -35px !important
</style>
