<template>
  <div
    class="row justify-content-center welcome"
    :class="['pb-3 pt-4 pt-md-2', menuIsOpen ? 'px-1' : 'px-0 px-md-5']"
  >
    <div class="col-lg-8 ps-0 pe-0 pe-lg-3 mb-3 mb-lg-0">
      <div class="user-info">
        <img
          class="capa"
          :src="imgCapa"
          data-target="#modalImageBanner"
          @error="$event.target.src = require('@/assets/images/bg-lives.png')"
        />

        <div class="w-100 content-info">
          <div class="title-bordered">
            <div class="row align-items-center">
              <div class="col-auto pe-0">
                <span class="mb-2 bold bold-xs-none title">
                  Olá, {{ user.nome }} {{ user.sobrenome }}
                </span>
              </div>
              <div class="col-auto ps-2">
                <TagMode :is-pro="user.isPro" />
              </div>
            </div>
            <h6 class="dark-grey-2">
              Sua jornada conosco começou dia
              {{ $f.converterDataPadraoCheio(user.createdAt) }}
            </h6>
          </div>

          <div
            class="d-flex mt-1 flex-wrap flex-sm-row justify-content-between"
          >
            <div class="conquistas">
              <h6 v-if="conquistas.length" class="font-15 text-white mt-1">
                Suas conquistas:
              </h6>

              <div class="d-flex mt-1 conquests-list">
                <div
                  class="px-1"
                  v-for="(conquista, ind) in conquistas"
                  :key="`conquista-perfil-${ind}`"
                >
                  <img
                    class="img-perfil"
                    :src="conquista.src"
                    :title="conquista.titulo"
                  />
                </div>
              </div>
            </div>

            <div class="d-flex align-items-end btns">
              <div class="col-auto col-sm px-1">
                <router-link
                  :to="{ name: 'perfil', params: { user: user.id } }"
                >
                  <BtnPink label="Ver Perfil" class="bg-btn-pink bold upper" />
                </router-link>
              </div>

              <div class="col-auto col-sm px-1">
                <router-link :to="{ name: 'dados' }">
                  <BtnPink
                    label="Editar Perfil"
                    class="bg-btn-pink bold upper"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 cursos mb-4 mb-md-0">
      <div class="title-bordered">
        <h3 class="mb-0 mb-md-2 bold bold-xs-none title">
          Está com alguma duvida?
        </h3>

        <h6 class="dark-grey-2 mb-0">#postanocafé. A comunidade do PJA+</h6>
      </div>

      <div class="col-auto text-end">
        <router-link :to="{ name: 'cafe' }">
          <BtnPink class="bg-btn-pink upper" label="Ver Café" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/Mixins";
import { mapGetters } from "vuex";
import BtnPink from "@/components/shared/forms/ButtonPink";

export default {
  name: "WelcomeComp",

  mixins: [Mixins],

  components: { BtnPink },

  props: {
    user: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters({
      person: "perfil/getPerson",
      wins: "registration/getWins",
      menuIsOpen: "configs/menuIsOpen",
      isLoadingData: "perfil/isLoading",
    }),
    imgCapa() {
      if (this.isLoadingData) return require("@/assets/images/bg-lives.png");
      return (
        /* this.person?.dadosEducando?.[0]?.capa */ this.mountImagePath(
          "capa",
          this.user.id,
          this.user.extensaoCapaPerfil
        ) || ""
      );
    },
    conquistas() {
      return (
        this.wins?.map(({ id, extensaoImagem, titulo }) => {
          const src = this.mountImagePath("conquista", id, extensaoImagem);
          return { src, titulo };
        }) || []
      );
    },
  },

  created() {},

  methods: {},
};
</script>
<style lang="sass" scoped>
.welcome
  .user-info
    height: 100%
    position: relative
    background-color: #000
    border-radius: 10px
    img.capa
      width: 100%
      height: 100%
      position: absolute
      border-radius: 10px
      filter: brightness(25%)
    .content-info
      z-index: 1
      overflow: hidden
      position: relative
      padding: 30px 15px 15px 40px
      h3
        overflow: hidden
        white-space: nowrap
        letter-spacing: initial
        text-overflow: ellipsis
      .conquistas
        padding-left: 12px
      .btns
        align-self: flex-end
        .bg-btn-pink
          text-wrap: nowrap !important
          padding: 5px 10px !important
          font-size: 11px !important
  .cursos
    height: 100%
    display: flex
    border-radius: 10px
    flex-direction: column
    background-color: #ff4163
    justify-content: space-between
    .title
      height: auto !important
    .title-bordered
      border-color: #ffff
      margin: 25px 15px
    .bg-btn-pink
      width: fit-content
      margin: 0 15px 15px
      padding: 5px 25px !important
      background-color: #151515 !important
  .pink:hover
    opacity: .9
    transition: all .15s
  .img-perfil
    width: 25px
    height: 25px
    border-radius: 50%
    object-fit: contain
  .conquistas
    max-width: 60%
  .conquests-list
    width: auto
    overflow-x: auto
    &::-webkit-scrollbar
      height: 2px
      background: #222 !important
    &::-webkit-scrollbar-thumb
      background: #ff416377
@media (max-width: 1024px)
  .cursos
    height: auto !important
    .title-bordered
      margin: 15px 5px !important
  .welcome
    .user-info
      height: auto
      .content-info
        .btns
          .bg-btn-pink
            padding: 3px 10px !important
  .conquistas
    width: 100% !important
    max-width: 100% !important
  .conquests-list
    padding-bottom: 5px
    margin-bottom: 10px
    overflow-y: hidden !important
    &::-webkit-scrollbar
      height: 2px
      background: #222 !important
    &::-webkit-scrollbar-thumb
      background: #ff416377
  .content-info
    padding: 15px 15px 10px 15px !important
  .title
    font-size: 16px !important
  .dark-grey-2
    font-size: 14px !important
  .text-white
    font-size: 14px !important
</style>
