<template>
  <section
    id="lives"
    :class="['pb-3 pt-2', menuIsOpen ? 'px-1' : 'px-0 px-md-5']"
    v-if="isLoading || lives.length"
  >
    <h3 class="ms-2 mb-md-3 mb-2 bold bold-xs-none title title-bordered">
      Últimos vídeos
    </h3>

    <LoadingComp v-if="isLoading" />

    <Slider
      v-else-if="lives.length"
      :images="lives"
      :slides-to-show="slidesToShow"
      father-class="doc-slider"
      :is-iframe="true"
      :child="cardLive"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import CardLive from './CardLive.vue'
import Slider from '@/components/shared/Slider'
import SharedService from '@/service/sharedServices'

export default {
  name: 'DocumentariesComp',
  components: {
    Slider
  },

  data () {
    return {
      cardLive: CardLive
    }
  },

  async created () {
    if (this.isLoading || this.lives.length) return
    this.SharedService = new SharedService(this.$resource)

    this.$store.dispatch('lives/setIsLoading', true)
    const { body: lives } = await this.SharedService.lives()

    const orderedLives = lives.map(live => ({
      titulo: live.titulo,
      order: Number(live.titulo.split('-')[0].replace(/\D/gim, '')),
      descricao: live.descricao,
      video: this.mountLive(live.linkYoutube)
    })).sort((a, b) => a.order < b.order ? 1 : -1)

    this.$store.dispatch('lives/setIsLoading', false)
    this.$store.dispatch('lives/setLives', orderedLives)
  },

  computed: {
    slidesToShow () {
      if (this.width < 600) return 2
      if (this.width < 800) return 3
      return 3
    },

    ...mapGetters({
      lives: 'lives/getLives',
      width: 'configs/getWidth',
      isLoading: 'lives/isLoading',
      menuIsOpen: 'configs/menuIsOpen'
      // documentarios: 'documentarios/getDocumentarios'
    })
  },

  methods: {
    mountLive (link) {
      return link.split('/').pop()
    },
    mountImage (item) {
      return {
        image: `${this.$baseApiURL}/acesso-arquivo?caminho=curso&identificador=${item.id}&extensao=${item.extensaoBanner}&expressao=horizontal`,
        id: item.id
      }
    }
  }
}
</script>
<style lang="sass">
.doc-slider
  .arrow-right
    right: -35px !important
  .arrow-left
    left: -35px !important

@media (max-width: 500px)
  h1
    font-size: 1.5em !important
</style>
