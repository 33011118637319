<template>
  <div>
    <a target="_blank" :href="$checkoutAnual" rel="noopener noreferrer">
      <img
        v-if="banner === 1"
        class="w-100 pointer with-events"
        src="@/assets/images/banner-free.png"
      />
      <img
        v-if="banner === 2"
        class="w-100 pointer with-events"
        src="@/assets/images/banner-free2.png"
      />
      <img
        v-if="banner === 3"
        class="w-100 pointer with-events"
        src="@/assets/images/banner-free3.png"
      />
      <img
        v-if="banner === 4"
        class="w-100 pointer with-events"
        src="@/assets/images/banner-free4.png"
      />
      <img
        v-if="banner === 5"
        class="w-100 pointer with-events"
        src="@/assets/images/banner-free5.png"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "PropagandaComp",

  props: {
    banner: {
      default: 1,
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
