<template>
  <section class="cursos-content">
    <div
      v-if="isLoadingCurso || isLoadingDoc || !user.id"
      class="center-loading"
    >
      <LoadingComp class="pt-5 mt-5" />
    </div>

    <div v-else class="enter-effect-opacity">
      <Campaings v-if="!isMobile && user.id" />

      <!-- <div class="row justify-content-center pt-md-4 pt-1 mb-4 px-xl-0"> -->
      <!-- </div> -->

      <div class="pt-md-4 pt-1 cursos-contents">
        <div class="px-0 px-md-4">
          <div class="row justify-content-center px-md-3">
            <Welcome :user="user" v-if="user.id" />
          </div>
          <!-- <router-link
            :to="{ name: 'home' }"
            class="pink font-16 float-end me-5 pe-1"
          >
            <ReturnIcon class="me-1" />
            Voltar
          </router-link> -->

          <MoreCourses class="pb-3" v-if="user.id" />
        </div>

        <div class="px-md-5">
          <div class="px-0 px-md-4">
            <PropagandaFree class="mb-4" v-if="!user.isPro && user.id" />
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-12">
            <Depoiments class="pb-3" v-if="user.id && !user.isPro" />
          </div>
        </div> -->

        <div class="px-0 px-md-4">
          <Lives class="pb-3" v-if="user.id" />

          <AllCourses
            title="Explore nossos cursos"
            class="pb-3"
            v-if="user.id"
          />
        </div>

        <div class="px-md-5">
          <div class="px-0 px-md-4">
            <PropagandaFree
              :banner="2"
              class="mb-4"
              v-if="!user.isPro && user.id"
            />
          </div>
        </div>

        <div class="px-0 px-md-4">
          <AllCourses
            title="Workshops"
            filter="workshop"
            class="pb-2 pb-md-5"
            v-if="user.id"
          />

          <AllCourses
            title="Em breve"
            filter="disponivelEmBreve"
            class="pb-2 pb-md-5"
            v-if="user.id"
          />

          <slot name="parceiros" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Lives from "./Lives.vue";
import { mapGetters } from "vuex";
import Campaings from "./Campaings.vue";
import AllCourses from "./AllCourses.vue";
import Depoiments from "./Depoiments.vue";
import MoreCourses from "./MoreCourses.vue";
import ReturnIcon from "@/assets/icons/Return";
import UserService from "@/service/userService";
import Welcome from "@/components/shared/Welcome.vue";

export default {
  name: "HomeComp",

  components: {
    Lives,
    Welcome,
    Depoiments,
    Campaings,
    ReturnIcon,
    AllCourses,
    MoreCourses,
  },

  computed: {
    ...mapGetters({
      isMobile: "configs/isMobile",
      user: "registration/getUser",
      menuIsOpen: "configs/menuIsOpen",
      isLoadingCurso: "cursos/isLoading",
      isLoadingDoc: "documentarios/isLoading",
      isValidatedWins: "registration/getValidatedWins",
    }),
  },

  mounted() {
    this.UserService = new UserService(this.$resource);
    if (this.isValidatedWins) return;
    this.buscarConquistas();
  },

  watch: {
    user: {
      deep: true,
      handler() {
        this.buscarConquistas();
      },
    },
  },

  methods: {
    async buscarConquistas() {
      if (!this.user.id) return;

      const { body: conquistasEducando } = await this.UserService.conquistas();
      const mapConquistas = conquistasEducando?.map((conq) => ({
        ...conq,
        image: this.mountImg(conq.id, conq.extensaoImagem),
      }));
      this.$store.dispatch("registration/setValidateWins");
      await this.$store.dispatch("registration/setWins", {
        wins: mapConquistas,
        resource: this.$resource,
      });
    },

    mountImg(id, ext) {
      return `${this.$baseApiURL}/acesso-arquivo?caminho=conquista&identificador=${id}&extensao=${ext}`;
    },
  },
};
</script>
<style lang="sass" scoped>
.cursos-content
  position: relative
  &:after
    content: ''
    top: 0px
    right: 0px
    height: 100%
    min-height: 100vh
    width: 35px
    position: absolute
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 80%)
.bg
  background-color: rgba(21,21,21,1)
.max-height-100vh
  max-height: 100vh
.pm-none
  margin: 0px !important
  padding: 0px !important
.center-loading
  padding-top: 35vh
.cursos-contents
  align-items: center
  // text-align: center
</style>
