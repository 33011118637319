<template>
  <div class="scrolling-templates">
    <LoadingComp v-if="isLoading" class="mt-3" />

    <div v-else-if="temporadas.length" class="px-0 pb-3 pt-2 px-md-4 mt-0">
      <p class="text-end mb-2 pe-4">
        <span class="pink pointer font-13" @click="expandirTudo()">
          Expandir
          <i
            :class="['fa font-11 ms-1', isAllOpened ? 'fa-minus' : 'fa-plus']"
          />
        </span>
      </p>

      <div
        class="bg-black mb-1 mx-md-0 mx-1"
        v-for="temp in temporadas"
        :key="`${temp.idTemporada}-temp`"
      >
        <div @click="openList(temp)" class="temp-header w-100 pointer py-2">
          <span
            :class="[
              'mb-0 font-14 pt-1',
              $route.params.modulo == temp.idTemporada ? 'pink' : '',
            ]"
          >
            {{ temp.titulo }}
          </span>

          <span>
            <Chevron
              :class="[
                'chevron me-1',
                temp.isOpen ? 'chevron-down' : 'chevron-up',
              ]"
            />
          </span>
        </div>

        <div
          :class="[
            'collapsed d-grid py-0 px-2 px-md-1',
            temp.isOpen ? 'show py-2' : '',
          ]"
        >
          <TemporadaItem
            :video="vid"
            v-for="vid in temp.videos"
            :key="`${vid.idVideo}-video`"
            :id-temporada="temp.idTemporada"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TemporadaItem from "./TemporadaItem";
import Chevron from "@/assets/icons/Chevron";

export default {
  components: { Chevron, TemporadaItem },

  data() {
    return {
      thumbs: {},
      firstLoad: false,
      isAllOpened: false,
    };
  },

  mounted() {
    this.loadFirst();
  },

  computed: {
    ...mapGetters({
      isLoading: "temporadas/isLoading",
      temporadas: "temporadas/getTemporadas",
    }),
  },

  methods: {
    loadFirst() {
      const temp = this.temporadas.find(({ isOpen }) => isOpen);
      if (!temp) {
        setTimeout(() => this.loadFirst(), 1000);
        return;
      }

      this.mountThread(temp);

      setTimeout(() => {
        const { modulo, video } = this.$route.params;
        const dist = document.getElementById(`${modulo}-${video}-scroll-thumb`);

        dist.scrollIntoView({
          block: "end",
          behavior: "smooth",
          inline: "end",
        });
      }, 1000);
    },

    goTo(modulo, video, idTemporadaVideo) {
      const curso = this.$route.params.id;
      this.$router
        .push({
          name: "video",
          params: { id: curso, modulo, video, idTemporadaVideo },
        })
        .catch((e) => e);
    },

    async expandirTudo() {
      this.isAllOpened = !this.isAllOpened;
      for (const temporada of this.temporadas) {
        temporada.isOpen = this.isAllOpened;
        this.mountThread(temporada);
      }
    },

    async mountThread() {
      const temps = this.temporadas;
      this.$store.dispatch("temporadas/setTemporadas", []);
      this.$store.dispatch("temporadas/setTemporadas", temps);
      return true;
    },

    openList(temp) {
      temp.isOpen = !temp.isOpen;
      this.mountThread(temp);
    },

    mountTime(time) {
      const curr = `${time}`;
      if (curr.length === 1) return `0${curr}:00`;
      if (curr.length === 2) return `${curr}:00`;
      return time;
    },

    mountTitle(title) {
      return title.split("-").pop().trim();
    },
  },
};
</script>

<style lang="sass" scoped>
.is-current-video
  background: #1a1a1a

.video
  padding-left: 30px
  padding-right: 20px
  &:hover
    background: #171717

.link
  &:hover
    .dark-grey-2
      color: #646464 !important

    .title-video
      color: #fff !important

.thumbnail
  width: 80px
  object-fit: contain

.relative
  position: relative

.checkIcon
  width: 13px
  left: -23px
  position: absolute
  top: 50%
  transform: translateY(-50%)

.accordion
  max-height: 500px

a
  text-decoration: none

::-webkit-scrollbar
  width: 3px
  background: #222 !important
  border-radius: 3px

::-webkit-scrollbar-track
  border-radius: 3px

::-webkit-scrollbar-thumb
  border-radius: 3px
  background: #ff4163
  max-height: 50px

.scrolling-templates
  max-height: calc(100vh - 115px - 180px)
  overflow: scroll
  overflow-x: hidden

.bg-black
  border-radius: 3px
  border: 1px solid #222

.temp-header
  display: inline-flex
  justify-content: space-between
  padding-right: 20px
  padding-left: 30px

.chevron
  transition: all .25s

.chevron-down
  transform: rotate(180deg)

.clock
  width: 13px
  margin-top: -3px

.collapsed
  opacity: 0
  max-height: 0px
  overflow: hidden
  transition: all .5s

.show
  opacity: 1
  padding-top: 10px
  padding-bottom: 10px
  transition: max-height 2s, opacity 1s, padding-top .5s !important
  max-height: 10000px !important

@media (max-width: 500px)
  .temp-header
    &:after
      width: 15px
      height: 15px
      left: -2px

  .scrolling-templates
    overflow: visible !important

  ::-webkit-scrollbar
    width: 3px

  h5
    font-size: 14px !important

  li
    font-size: 14px !important
    margin-bottom: 3px !important
</style>
