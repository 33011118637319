<template>
  <section
    :class="['pb-3 pt-2', menuIsOpen ? 'px-1' : 'px-0 px-md-5']"
    v-if="isLoading || allCourses.length"
  >
    <h3 class="ms-2 mb-md-3 mb-2 bold bold-xs-none title title-bordered">
      {{ title }}
    </h3>

    <LoadingComp v-if="isLoading" />
    <Slider
      v-else-if="allCourses.length"
      :show-infos="true"
      :images="allCourses"
      :slides-to-show="this.slidesToShow"
      :rows="getRows"
      :father-class="'doc-slider'"
    />
  </section>
</template>

<script>
import Slider from '@/components/shared/Slider'
import CursoService from '@/service/cursoService'
import { mapGetters } from 'vuex'

export default {
  props: {
    filter: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },

  components: {
    Slider
  },

  async created () {
    if (this.isLoading || this.cursos.length) return
    this.CursoService = new CursoService(this.$resource)
    this.$store.dispatch('cursos/setIsLoading', true)
    const { body: cursos } = await this.CursoService.cursosAtivos()
    this.$store.dispatch('cursos/setIsLoading', false)
    this.$store.dispatch('cursos/setCursos', { cursos, url: this.$baseApiURL })
  },

  computed: {
    getRows () {
      const divisor = this.isMobile ? 2 : 4
      if (!this.cursos.length) return 1
      return Math.ceil(this.allCourses.length / divisor)
    },

    slidesToShow () {
      if (this.width < 600) return 2
      if (this.width < 800) return 3
      return 4
    },

    ...mapGetters({
      menuIsOpen: 'configs/menuIsOpen',
      isMobile: 'configs/isMobile',
      width: 'configs/getWidth',
      isLoading: 'cursos/isLoading',
      cursos: 'cursos/getCursos'
    }),

    allCourses () {
      if (!this.filter) return this.cursos.filter(({ disponivelEmBreve, workshop }) => disponivelEmBreve === 'N' && (!workshop || workshop === 'N'))
      if (this.filter === 'disponivelEmBreve') return this.cursos.filter(({ disponivelEmBreve, workshop }) => workshop === 'N' && disponivelEmBreve === 'S')
      return this.cursos.filter(curso => curso[this.filter] === 'S')
    }
  }
}

</script>

<style lang="sass" scoped>
@media (max-width: 500px)
  h1
    font-size: 1.5em !important
</style>
