<template>
  <section
    :class="[
      'bg-dark border-radius-10 mb-3 container-followers py-3 w-100',
      search ? 'pb-5' : '',
    ]"
  >
    <div class="row justify-content-between px-4">
      <div class="col">
        <span
          class="mb-1 font-18"
          data-toggle="collapse"
          href="#collapse-search"
          @click="setFocus()"
        >
          Alunos para se conectar
        </span>
      </div>
      <div class="col-auto">
        <SearchIcon
          class="pointer"
          data-toggle="collapse"
          href="#collapse-search"
          @click.native="setFocus()"
        />
      </div>
    </div>

    <div class="collapse" id="collapse-search">
      <InputLinks
        v-model="search"
        icon="search"
        placeholder="Pesquise aqui"
        personal-styles="width: 10px !important;fill: #5d5d5d !important"
        color="white"
        id="input-search"
        :has-padding="false"
        :has-bars="false"
        class="px-4"
      />
    </div>

    <div id="area-follower" class="scroll p-3" @scroll="scroll()">
      <div v-if="!msg">
        <div
          v-for="(person, ind) in personsToFollowing"
          v-show="!person.following || (person.following && search)"
          :key="`follower-${person.id}-${ind}`"
          class="mb-3 follower-card"
        >
          <div class="box-image-person me-2 position-relative">
            <router-link
              class="white font-13 link-person"
              :to="{ name: 'perfil', params: { user: person.id } }"
            >
              <img
                v-if="person.img && !errors.includes(person.id)"
                class="img-person with-events"
                :src="person.img"
                @error="setError(person)"
              />
              <div
                v-else
                class="d-inline-flex justify-content-center img-person upper bg-black align-items-center"
              >
                {{ person.name[0] }}
              </div>
            </router-link>
            <div
              :class="[
                'status-tag position-absolute',
                person.isPro ? 'bg-pink' : 'bg-free',
              ]"
            />
          </div>

          <div class="d-flex flex-column box-person pe-1">
            <router-link
              class="white font-13 link-person"
              :to="{ name: 'perfil', params: { user: person.id } }"
            >
              {{ person.name }}
            </router-link>

            <div class="conquistas">
              <img
                :title="conq.titulo"
                class="img-conquest me-1"
                v-for="conq in person.conquistas"
                :key="`conquista-followers-${person.id}-${conq.id}`"
                :src="mountImagePath('conquista', conq.id, conq.extensaoImagem)"
              />
            </div>
          </div>

          <div class="button">
            <button
              @click="handleFollow(person)"
              :disabled="person.loadFollowing"
              :class="[
                'white font-12',
                person.following ? 'btn-fill-green' : 'btn-green',
              ]"
            >
              <div class="loading-btn" v-if="person.loadFollowing" />
              <span v-else>
                {{ person.following ? "Seguindo" : "Seguir" }}
              </span>
            </button>
          </div>
        </div>
      </div>

      <p v-else class="dark-grey-2 text-center font-13">
        {{ msg }}
      </p>

      <p
        title="Carregar mais"
        @click="getUnfollowings()"
        v-if="!isLoading && !search"
        :class="[
          'pointer dark-grey-2 text-center font-13 hovered pb-2',
          searchIsOpened ? 'mb-5' : '',
        ]"
      >
        Carregar mais...
      </p>

      <LoadingComp v-if="isLoading" :class="searchIsOpened ? 'mb-5' : ''" />
    </div>
  </section>
</template>

<script>
import Mixins from "@/Mixins";
import { mapActions, mapGetters } from "vuex";
import SearchIcon from "@/assets/icons/Search";
import FollowService from "@/service/followService";
import SharedServices from "@/service/sharedServices";
import InputLinks from "@/components/shared/forms/InputLinks";

export default {
  name: "FollowersComp",

  mixins: [Mixins],

  components: {
    InputLinks,
    SearchIcon,
  },

  data() {
    return {
      msg: "",
      count: 0,
      tried: 0,
      search: "",
      errors: [],
      isLoading: false,
      isFiltered: false,
      direction: "down",
      searchIsOpened: false,
    };
  },

  async created() {
    this.FollowService = new FollowService(this.$resource);
    this.SharedResource = new SharedServices(this.$resource);

    if (this.personsToFollowing.length) return;
    this.getUnfollowings();
  },

  beforeDestroy() {
    this.FollowService = null;
    this.SharedResource = null;
  },

  methods: {
    setError(person) {
      this.errors.push(person.id);
    },

    scroll() {
      const alturaScroll = document.getElementById("area-follower");

      const { clientHeight, scrollHeight, scrollTop } = alturaScroll;
      const position = clientHeight + scrollTop;
      this.direction = position >= scrollHeight - 50 ? "up" : "down";
    },

    setFocus() {
      this.searchIsOpened = !this.searchIsOpened;
      setTimeout(() => {
        document.getElementById("input-search").focus();
      }, 200);
    },

    async handleFollow(person) {
      const conditional = person.following;
      person.following = !person.following;

      if (conditional)
        await this.FollowService.unfollow({ idEducando: person.id });
      if (!conditional)
        await this.FollowService.follow({ idEducando: person.id });
      if (this.isCurrentUser) this.setFollowersCount(conditional);

      const isCurrentPerfil =
        Number(this.$route.params.user) === Number(person.id);
      if (isCurrentPerfil) this.setFollowing(!conditional);
      if (isCurrentPerfil && !this.isCurrentUser)
        this.setFollowingsCount(conditional);
    },

    mapPersons(list, following = false) {
      return list.map((person) => ({
        ...person,
        isPro: person.assinatura === "P",
        hasError: false,
        loadFollowing: false,
        name: `${person.nome} ${person.sobrenome || ""}`.trim(),
        following: person.seguindo ? person.seguindo !== "N" : following,
        conquistas:
          person?.conquistas?.sort((a) => (a.id === 12 ? -1 : 0)) || [],
        img: this.mountImagePath("educando", person.id, person.extensaoImagem),
      }));
    },

    async getUnfollowings() {
      if (this.tried === 7) {
        this.isLoading = false;
        this.tried = 0;
        return;
      }

      this.isLoading = true;
      this.tried = this.tried + 1;

      const { body: unfollowings, status } =
        await this.FollowService.unfollowings();
      if (!isValidStatus(status)) return (this.isLoading = false);

      const persons = this.mapPersons(unfollowings, false);
      if (!persons.length) return this.getUnfollowings();

      const filteredPersons = persons.filter((person) => {
        const isLoaded = this.followers.some(({ id }) => id === person.id);
        const isCurretUser = this.currentUser.id === person.id;
        if (isLoaded || isCurretUser) return false;
        return person;
      });

      if (!filteredPersons.length) return this.getUnfollowings();
      this.tried = 0;

      this.isLoading = false;
      this.setFollowers(filteredPersons);
    },

    async getFilter(filtro) {
      this.isLoading = true;
      const { body } = await this.FollowService.unfollowingsByName({ filtro });
      const persons = this.mapPersons(body).filter(
        ({ id }) => this.currentUser.id !== id
      );
      this.setFollowers(persons);
      this.isLoading = false;
      if (!persons.length) this.msg = "Nenhum resultado foi encontrado.";
    },

    ...mapActions({
      setEmpty: "followers/setEmpty",
      setFollowing: "perfil/setFollowing",
      setFollowers: "followers/setFollowers",
      setFollowersCount: "perfil/setFollowersCount",
      setFollowingsCount: "perfil/setFollowingsCount",
    }),
  },

  computed: {
    ...mapGetters({
      currentUser: "registration/getUser",
      followers: "followers/getFollowers",
      perfil: "perfil/getPerson",
    }),

    personsToFollowing() {
      return this.followers.filter(
        (follow, ind) => this.followers.indexOf(follow) === ind
      );
    },

    isCurrentUser() {
      return Number(this.$route.params.user) === Number(this.currentUser.id);
    },

    isCurrentPerfil() {
      return Number(this.perfil.id) === Number(this.$route.params.user);
    },
  },

  watch: {
    direction: {
      handler(direction) {
        if (direction === "up" && !this.isLoading) this.getUnfollowings();
      },
    },

    search: {
      deep: true,
      handler(filtro) {
        this.msg = "";
        if (!filtro || !filtro.length) {
          this.isFiltered = false;
          this.setEmpty();
          this.getUnfollowings();
          return;
        }
        setTimeout(() => {
          if (filtro.length < 3) return;

          if (filtro !== this.search) return;
          this.isFiltered = true;
          this.setEmpty();
          this.getFilter(filtro);
        }, 400);
      },
    },
  },
};

const isValidStatus = (status) => Boolean(status >= 200 && status < 300);
</script>

<style lang="sass" scoped>
.perfil .scroll
  min-height: 50vh !important
.container-followers
  user-select: none
  width: 100% !important
  .load-more
    font-size: 25px
  .follower-card
    display: grid
    grid-template-columns: 50px calc(100% - 115px) 65px !important
    .box-person
      max-width: 90%
      .link-person
        max-width: 100%
        overflow: hidden
        width: fit-content
        white-space: nowrap
        text-overflow: ellipsis

      .conquistas
        max-width: 100px
        overflow: hidden
        overflow-x: scroll
        padding-right: 10px
        display: inline-flex
        .img-conquest
          width: 22px
          height: 22px
          border-radius: 50%
          object-fit: contain !important

        &::-webkit-scrollbar
          height: 0px !important

    .box-image-person
      .img-person
        width: 40px
        height: 40px
        object-fit: cover
        border-radius: 50%

    .button
      %button
        border: 1px solid white
        border-radius: 3px
        background-color: #618f68
        width: 65px
        padding: 0px
        .loading-btn
          width: 13px
          height: 13px
          border-radius: 50%
          border: 1px dashed #fff
          animation: rotate 1500ms infinite
          margin: auto
          margin-top: 2px
          margin-bottom: 2px

      .btn-fill-green
        @extend %button
        background-color: #618f68
      .btn-green
        @extend %button
        background-color: transparent

@keyframes rotate
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

@media (max-width: 600px)
  .bg-dark
    background-color: #000 !important
  .container-followers
    .follower-card
      grid-template-columns: 50px calc(100% - 115px) 65px !important
    #area-follower
      height: 300px !important
      max-height: 300px !important
      overflow-y: auto !important
    .font-18
      font-size: 16px !important
</style>
