var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:[
    'bg-dark border-radius-10 mb-3 container-followers py-3 w-100',
    _vm.search ? 'pb-5' : '' ]},[_c('div',{staticClass:"row justify-content-between px-4"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"mb-1 font-18",attrs:{"data-toggle":"collapse","href":"#collapse-search"},on:{"click":function($event){return _vm.setFocus()}}},[_vm._v(" Alunos para se conectar ")])]),_c('div',{staticClass:"col-auto"},[_c('SearchIcon',{staticClass:"pointer",attrs:{"data-toggle":"collapse","href":"#collapse-search"},nativeOn:{"click":function($event){return _vm.setFocus()}}})],1)]),_c('div',{staticClass:"collapse",attrs:{"id":"collapse-search"}},[_c('InputLinks',{staticClass:"px-4",attrs:{"icon":"search","placeholder":"Pesquise aqui","personal-styles":"width: 10px !important;fill: #5d5d5d !important","color":"white","id":"input-search","has-padding":false,"has-bars":false},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"scroll p-3",attrs:{"id":"area-follower"},on:{"scroll":function($event){return _vm.scroll()}}},[(!_vm.msg)?_c('div',_vm._l((_vm.personsToFollowing),function(person,ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!person.following || (person.following && _vm.search)),expression:"!person.following || (person.following && search)"}],key:("follower-" + (person.id) + "-" + ind),staticClass:"mb-3 follower-card"},[_c('div',{staticClass:"box-image-person me-2 position-relative"},[_c('router-link',{staticClass:"white font-13 link-person",attrs:{"to":{ name: 'perfil', params: { user: person.id } }}},[(person.img && !_vm.errors.includes(person.id))?_c('img',{staticClass:"img-person with-events",attrs:{"src":person.img},on:{"error":function($event){return _vm.setError(person)}}}):_c('div',{staticClass:"d-inline-flex justify-content-center img-person upper bg-black align-items-center"},[_vm._v(" "+_vm._s(person.name[0])+" ")])]),_c('div',{class:[
              'status-tag position-absolute',
              person.isPro ? 'bg-pink' : 'bg-free' ]})],1),_c('div',{staticClass:"d-flex flex-column box-person pe-1"},[_c('router-link',{staticClass:"white font-13 link-person",attrs:{"to":{ name: 'perfil', params: { user: person.id } }}},[_vm._v(" "+_vm._s(person.name)+" ")]),_c('div',{staticClass:"conquistas"},_vm._l((person.conquistas),function(conq){return _c('img',{key:("conquista-followers-" + (person.id) + "-" + (conq.id)),staticClass:"img-conquest me-1",attrs:{"title":conq.titulo,"src":_vm.mountImagePath('conquista', conq.id, conq.extensaoImagem)}})}),0)],1),_c('div',{staticClass:"button"},[_c('button',{class:[
              'white font-12',
              person.following ? 'btn-fill-green' : 'btn-green' ],attrs:{"disabled":person.loadFollowing},on:{"click":function($event){return _vm.handleFollow(person)}}},[(person.loadFollowing)?_c('div',{staticClass:"loading-btn"}):_c('span',[_vm._v(" "+_vm._s(person.following ? "Seguindo" : "Seguir")+" ")])])])])}),0):_c('p',{staticClass:"dark-grey-2 text-center font-13"},[_vm._v(" "+_vm._s(_vm.msg)+" ")]),(!_vm.isLoading && !_vm.search)?_c('p',{class:[
        'pointer dark-grey-2 text-center font-13 hovered pb-2',
        _vm.searchIsOpened ? 'mb-5' : '' ],attrs:{"title":"Carregar mais"},on:{"click":function($event){return _vm.getUnfollowings()}}},[_vm._v(" Carregar mais... ")]):_vm._e(),(_vm.isLoading)?_c('LoadingComp',{class:_vm.searchIsOpened ? 'mb-5' : ''}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }